.App {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  font-family: 'Montserrat', sans-serif;
   /* background-color: #262626; */
  background-color: #fff;
}

@media (max-width: 480px){
  .App {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .App {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .App {
    width: 50%;
    height: 100%;
    margin: 0 auto;
  }
}