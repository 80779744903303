.text {
	margin-top: 0px;
	margin-bottom: 0px;
}
.text p{
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-bottom: 14px;
	font-weight: 400;
	color: #3F3F42;
	font-size: calc(16px + 2 * (100vw / 1200));
	line-height: calc(20px + 4 * (100vw / 1200));
}

@media (max-width: 767px) {
.text p{
	font-size: calc(16px + (2 + 2 * 0.7) * ((100vw - 320px) / 1200));

	line-height: calc(20px + (4 + 4 * 0.7) * ((100vw - 320px) / 1200));
}
}