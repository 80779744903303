.textPoint {
	font-weight: 700;
	padding-bottom: 5px;
	color: #3F3F42;
	margin-top: 4px;
	margin-bottom: 4px;
	letter-spacing: 0.4px;
	font-size: calc(16px + 2 * (100vw / 1200));
	line-height: calc(20px + 4 * (100vw / 1200));
}

@media (max-width: 767px) {
	.textPoint{
	font-size: calc(14px + (2 + 2 * 0.7) * ((100vw - 320px) / 1200));
	
	line-height: calc(20px + (4 + 4 * 0.7) * ((100vw - 320px) / 1200));
	}
}

.textPoint li {
	list-style-type: disc;
	text-decoration: underline;
	text-decoration-color: rgba(51, 51, 51, 0.33);
}


