.blockImg {
	margin: 0 auto;
	border: 1px solid #ccc;
}

.blockImg img{
	display: block;
	margin-left: auto;
    margin-right: auto;
	/* width: 100%; */
	box-sizing: border-box;
	margin-top: 8px;
	margin-bottom: 10px;
}