.title {
	width: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: -12px;
}

.title h1{
	color: #3F3F42;
	font-weight: 900;
	padding-left: 0px;
	border-bottom: 1px solid rgba(63, 63, 66, 0.3);
	font-size: calc(24px + 20 * (100vw / 1200));
	line-height: calc(26px + 24 * (100vw / 1200));
}

@media (max-width: 767px) {
	.title h1{
		font-size: calc(24px + (20 + 20 * 0.7) * ((100vw - 320px) / 1200));

		line-height: calc(26px + (24 + 24 * 0.7) * ((100vw - 320px) / 1200));
	}
}

