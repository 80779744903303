.link {
	display: inline-block;
	color: #696969;
}

.link a{
	margin-left: 2px;
	font-size: calc(8px + 4 * (100vw / 1200));
	text-decoration: none;
	color: #706c61;
}

.link a :hover{
	cursor: pointer;
}

@media (max-width: 767px) {
.link a{
	font-size: calc(8px + (4 + 4 * 0.7) * ((100vw - 320px) / 1200));
}
}