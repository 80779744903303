.textHeader {
	margin-top: 8px;
	margin-bottom: 6px;
}
.textHeader h3{
	color: #3f3f42;
	font-family: sans-serif;
	font-weight: 700;
	margin-block-start: 0em;
	margin-block-end: 0em;
	font-size: calc(16px + 2 * (100vw / 1200));
	line-height: calc(20px + 4 * (100vw / 1200));
}

.textHeader h3 a{
	font-family: 'Montserrat', sans-serif;
	color: #016190;
	font-weight: 700;
	text-decoration: none;
}
.textHeader h3 a:hover{
	color: #0490d6;
}


@media (max-width: 767px) {
	.textHeader h3{
		font-size: calc(16px + (2 + 2 * 0.7) * ((100vw - 320px) / 1200));
	
		line-height: calc(20px + (4 + 4 * 0.7) * ((100vw - 320px) / 1200));
	}
	}


.text p{
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-bottom: 14px;
	font-weight: 400;
	color: #3F3F42;
	font-size: calc(16px + 2 * (100vw / 1200));
	line-height: calc(20px + 4 * (100vw / 1200));
}

