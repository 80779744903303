.textHeader {
	margin-top: 8px;
	margin-bottom: 6px;
}
.textHeader h3{
	color: #3f3f42;
	font-family: sans-serif;
	font-weight: 700;
	margin-block-start: 0em;
	margin-block-end: 0em;
	font-size: calc(20px + 12 * (100vw / 1200));
	line-height: calc(22px + 14 * (100vw / 1200));
}


@media (max-width: 767px) {
.textHeader h3{
font-size: calc(18px + (14 + 14 * 0.7) * ((100vw - 320px) / 1200));

line-height: calc(22px + (14 + 14 * 0.7) * ((100vw - 320px) / 1200));
}
}