.wrapperContent {
	margin: 0;
	border-bottom: 1px solid rgba(63, 63, 66, 0.3);
	margin-bottom: 8px !important;;
}


@media (max-width: 480px) {
  .wrapperContent {
		margin: 0 10px;
	}
}
@media (max-width: 769px) {
  .wrapperContent {
		margin: 0 10px;
	}
}
