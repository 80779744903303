.aboutNews {
	display: inline-block;
	color: #706c61;
	font-size: calc(8px + 4 * (100vw / 1200));
	margin-bottom: 16px;
}

@media (max-width: 767px) {
.aboutNews{
	font-size: calc(8px + (4 + 4 * 0.7) * ((100vw - 320px) / 1200));
}
}