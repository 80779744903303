.img {
	position: relative;
	top: 0;
}

@media (max-width: 480px){
  .img {
		position: sticky;
		top: 0;
	}
}

@media (max-width: 768px) {
	.img {
		position: sticky;
		top: 0;
	}
}

.img img{
	width: 100%;
	box-sizing: border-box;
	max-height: 650px;
}